import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,{staticClass:"pa-6"},[_c('div',{staticClass:"mt-n6 mx-n6 mb-4 pa-4 d-flex",staticStyle:{"background-color":"#F5F5F5"}},[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Annuler ")])],1),_c('h2',{staticClass:"mb-3"},[_vm._v("Mis en place ?")]),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('DsfrAutocomplete',{staticClass:"mt-1",attrs:{"items":_vm.userCanteens,"clearable":"","multiple":"","hide-details":"","id":"select-canteen","placeholder":"Choisissez les établissements","no-data-text":"Pas de résultats","item-text":"name","item-value":"id"},model:{value:(_vm.chosenCanteenIds),callback:function ($$v) {_vm.chosenCanteenIds=$$v},expression:"chosenCanteenIds"}}),_c(VRow,{staticClass:"mt-2 pa-4"},[_c(VSpacer),_c(VBtn,{attrs:{"x-large":"","color":"primary"},on:{"click":_vm.saveActionChanges}},[_vm._v("Valider")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }