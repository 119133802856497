import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"d-flex mb-6",attrs:{"outlined":"","elevation":"0","color":"primary lighten-5"}},[_c(VIcon,{staticClass:"ml-4",attrs:{"color":"primary"}},[_vm._v("$information-fill")]),_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v(" La cantine « "+_vm._s(_vm.canteen.name)+" » sert des repas livrés par "),(_vm.centralKitchen.publicationStatus === 'published')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'CanteenPage',
            params: { canteenUrlComponent: this.$store.getters.getCanteenUrlComponent(_vm.centralKitchen) },
          }}},[_vm._v(" « "+_vm._s(_vm.centralKitchen.name)+" » ")]),_vm._v(" . ")],1):_c('span',[_vm._v("« "+_vm._s(_vm.centralKitchen.name)+" ».")]),_vm._v(" Les valeurs ci-dessous sont celles du lieu de production des repas. ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }